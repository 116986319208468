import { IS_DEV } from "@/__main__/constants.mjs";
import fetchCompsData from "@/game-tft/fetch-tft-comps-data.mjs";
import fetchOverlayConfiguration from "@/game-tft/tft-fetch-overlay-configuration.mjs";
import fetchOverlayData from "@/game-tft/tft-fetch-overlay-data.mjs";

export default function fetchData(params, searchParams) {
  return Promise.all([
    !IS_DEV && fetchOverlayConfiguration(),
    fetchOverlayData(),
    fetchCompsData(params, searchParams),
  ]);
}
