import getData from "@/__main__/get-data.mjs";
import TftOverlayConfiguration from "@/data-models/tft-overlay-configuration.mjs";
import * as API from "@/game-tft/api.mjs";
import { appLog } from "@/util/dev.mjs";

export default async function fetchData() {
  const configuration = await getData(
    API.getOverlayConfiguration(),
    TftOverlayConfiguration,
    ["volatile", "tft", "overlayConfiguration"],
    {
      shouldSkipDBRead: true,
    },
  ).catch((e) => appLog("Failed to fetch TFT overlay configuration", e));
  return configuration;
}
