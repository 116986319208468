import { isVolatile } from "@/__main__/constants.mjs";
import createModel, { arbitraryKeys } from "@/__main__/data-model.mjs";

const apiModelValidation = createModel({
  enabled: Boolean,
  [arbitraryKeys]: {
    enabled: Boolean,
  },
});

function transform(data) {
  data[isVolatile] = true;
  return apiModelValidation(data);
}

export default transform;
