import createModel, { Mapped } from "@/__main__/data-model.mjs";

const model = Mapped({
  key: String,
  value: {
    interestEarned: Mapped({ key: String, value: Number }),
    streakIncome: Mapped({ key: String, value: Number }),
  },
});
const TftIncomeStats = createModel({
  income_stats: model,
}).transform(model, (data) => data.income_stats);

export default TftIncomeStats;
