import getData from "@/__main__/get-data.mjs";
import TftIncomeStats from "@/data-models/tft-income-stats.mjs";
import * as API from "@/game-tft/api.mjs";

export default function fetchData(tier, patch) {
  return getData(API.getIncomeStats(tier, patch), TftIncomeStats, [
    "tft",
    "stats",
    "income",
    tier,
  ]);
}
